<template>
  <page fluid="lg">
    <b-form @submit.prevent="submit" autocomplete="off">
      <h2>{{ indicador.nombre }}: Importar mediciones</h2>
      <b-alert show variant="warning">
        Descarga la plantilla <b-link href="files/plantilla_importacion_mediciones.xlsx" download>aquí</b-link>
      </b-alert>
      <b-row>
        <b-col cols="12" md="8">
          <campo-archivo
            :required="true"
            :title="$t('Fichero de datos')"
            accept=".xlsx"
            v-model="documento"
            placeholder="Elige un archivo de MS Excel, y arrástralo aquí..."
          ></campo-archivo>
        </b-col>
        <b-col cols="12" md="3" class="mt-1">
          <b-form-group :label="$t('Acción')" cols="3">
            <b-form-select :options="comboAccion" required v-model="reemplazar"> </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <botonera-edicion url-cancelar="/administracion/indicadores"> </botonera-edicion>
    </b-form>
  </page>
</template>
<script>
import * as apiIndicadores from "@/services/indicadores.js";

export default {
  data() {
    return {
      indicador: {},
      reemplazar: 0,
      comboAccion: [
        {
          text: "-",
          value: "",
        },
        {
          text: "Añadir",
          value: "0",
        },
        {
          text: "Reemplazar",
          value: "1",
        },
      ],
      opciones: Object.freeze({
        ANIADIR: 1,
        REEMPLAZAR: 2,
      }),
      documento: {},
      accion: 0,
    };
  },
  methods: {
    async submit() {
      const indicadorId = this.$route.params.indicadorId;
      await apiIndicadores.importar(indicadorId, this.documento, this.reemplazar);
      this.$emit("userMessage", "Se han importado las mediciones");
      this.$router.push({ path: "/administracion/indicadores" });
    },
  },
  async created() {
    this.indicador = await apiIndicadores.cargar(this.$route.params.indicadorId);
  },
};
</script>
